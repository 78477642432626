<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage mb-3">Ticket Setting</h1>

    <div class="title-tabs">Setting</div>
    <div class="bg-white p-2 p-lg-3">
      <div class="row">
        <div class="col-sm-6 col-12">
          <InputText
            textFloat="Auto Closed Tickets (Days)"
            name="auto_closed_tickets"
            placeholder="Auto Closed Tickets (Days)"
            type="number"
            v-model="form.date_auto_close_ticket"
          />
          <b-form-checkbox
            id="default_show_assign_to_me"
            v-model="form.default_show_assign_to_me"
            :value="1"
            :unchecked-value="0"
            name="allowe-change"
          >
            <b>Default Show Only Assign To Me</b>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <FooterAction @submit="submit" routePath="/setting" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        date_auto_close_ticket: "",
        default_show_assign_to_me: 0,
      },
    };
  },

  created() {
    this.getData();
  },
  computed: {},
  methods: {
    async getData() {
      const res = await this.axios("/ticket/setting/autoclose");
      this.form.date_auto_close_ticket = res.data.detail;
    },
    async submit() {
      this.$bus.$emit("showLoading");
      if (this.form.date_auto_close_ticket == "")
        this.form.date_auto_close_ticket = 0;
      const res = await this.axios.post("/ticket/setting/autoclose", {
        value: this.form.date_auto_close_ticket,
      });
      this.$bus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert();
        this.$router.push("/setting");
      } else {
        this.errorAlert(res.data.message);
      }
    },
  },
};
</script>

<style>
.break-ele {
  flex-basis: 100%;
  height: 0;
}
.font-bold {
  font-weight: bold;
}
</style>
